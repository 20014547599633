<template>
    <div class="container">
        <van-field
            v-model="form.originalPassword"
            type="password"
            required
            label="旧密码"
            placeholder="请输入旧密码"
        />
        <van-field
            v-model="form.newPassword"
            type="password"
            required
            label="新密码"
            placeholder="请输入新密码"
        />
        <loading-button class="default-btn" text="修改密码" :submit="modify" />
    </div>
</template>

<script>
import { reactive } from "vue";
import { Toast } from "vant";
import { verifyEmpty, verifyPassword, goBack } from "@/utils";
import { modifyPassword } from "@/api";
import LoadingButton from "@/components/LoadingButton";

export default {
    name: "ModifyPassword",
    components: { LoadingButton },
    setup() {
        let form = reactive({
            originalPassword: "", // 旧密码
            newPassword: "", // 新密码
        });

        // 修改密码
        async function modify() {
            await verifyEmpty(form.originalPassword, "旧密码");
            await verifyPassword(form.newPassword, "新密码");
            await modifyPassword(form);
            Toast("修改成功");
            goBack();
        }

        return { form, modify };
    },
};
</script>

<style lang="less" scoped>
.container {
    flex: 1;
    background-color: #f2f2f2;
}
</style>
